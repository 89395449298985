import { SearchIcon } from '@chakra-ui/icons';
import * as server from 'config/Config';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { set } from 'date-fns';

const AddGroupMemberModal = ({
  isAddGroupMemberModal,
  setIsAddGroupMemberModal,
  currentGroup,
  searchList,
  setSearchList,
  setIdList,
  setLoading,
}) => {
  const toast = useToast();
  const admin = JSON.parse(localStorage.getItem('admin'));
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [isExistEmail, setIsExistEmail] = useState('');

  const closeAddGroupMemberModal = () => {
    setIsAddGroupMemberModal(false);
    setIsExistEmail(prev => null);
    reset({ groupMemberEmail: '' });
  };

  const addMemberInGroup = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${server.SERVER_URL}/user/list/search`,
        {
          page: 1,
          count: 1,
          membershipList: [0, 1, 3, 6],
          serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
          keyword: watch('groupMemberEmail'),
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      const existUser = response.data.data.find(
        userData => userData.user.email === watch('groupMemberEmail')
      );

      if (existUser) {
        setIsExistEmail(true);
      } else {
        setIsExistEmail(false);
        setLoading(false);
        return;
      }

      // 이미 멤버에 있으면 추가 안함

      if (searchList.find(user => user.email === existUser.user.email)) {
        toast({
          title: '이미 존재하는 멤버입니다.',
          description: '이미 멤버에 추가되었습니다.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      const addGroupUserResponse = await axios.post(
        `${server.SERVER_URL}/group/add-user`,
        {
          user_uid: existUser.user.user_uid,
          group_uid: currentGroup.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      toast({
        title: '그룹 멤버에 추가되었습니다.',
        description: '',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });

      setSearchList(prev => [...prev, addGroupUserResponse.data.data.user]);
      setIdList(prev => [
        ...prev,
        addGroupUserResponse.data.data.user.user_uid,
      ]);

      setIsAddGroupMemberModal(false);

      reset({ groupMemberEmail: '' });
    } catch (err) {
      toast({
        title: '그룹 멤버 추가에 실패했습니다.',
        description: '에러가 발생했습니다.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
    setLoading(false);
  };

  const verifyEmail = handleSubmit(async data => {
    try {
      const searchEmailListResponse = await axios.post(
        `${server.SERVER_URL}/user/list/search`,
        {
          page: 1,
          count: 1,
          membershipList: [0, 1, 3, 6],
          serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
          keyword: data.groupMemberEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      const findedEmail = searchEmailListResponse.data.data.find(
        userInfo => userInfo.user.email === data.groupMemberEmail
      );

      setIsExistEmail(!!findedEmail);
    } catch (err) {
      toast({
        title: '이메일 검색 실패',
        description: '에러가 발생했습니다.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  });

  return (
    <Modal isOpen={isAddGroupMemberModal} onClose={closeAddGroupMemberModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>그룹 멤버 추가하기</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p style={{ marginBottom: '20px' }}>
            멤버로 추가할 계정을 검색해주세요
          </p>
          <Flex alignItems={'center'}>
            <label style={{ width: '80px' }}>이메일</label>
            <Input
              placeholder="email"
              {...register('groupMemberEmail', { required: true })}
            />
            <Button onClick={verifyEmail}>
              <SearchIcon />
            </Button>
          </Flex>
          <Flex>
            <div style={{ width: '75px' }}></div>
            <FormControl isInvalid={!isExistEmail}>
              {isExistEmail && (
                <FormHelperText>존재하는 이메일 입니다.</FormHelperText>
              )}
              {isExistEmail === null && ''}
              {isExistEmail === false && (
                <FormErrorMessage>
                  존재하지 않는 이메일 입니다.
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={closeAddGroupMemberModal}>
            닫기
          </Button>
          <Button colorScheme="blue" onClick={addMemberInGroup}>
            추가하기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGroupMemberModal;
