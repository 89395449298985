import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Checkbox } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const MemberTable = ({ offset, searchList, checkedItems, CheckEach }) => {
  console.log(searchList);
  return (
    <tbody>
      {searchList.length !== 0 ? (
        searchList.map(item => (
          <tr key={item.user.user_uid} className="MemberCustom-tr">
            <td className="CheckBox textCenter">
              <Checkbox
                name="list"
                value={item.user.user_uid}
                colorScheme="blue"
                isChecked={checkedItems.includes(item.user.user_uid)}
                onChange={e => CheckEach(e, item.user.user_uid)}
              />
            </td>
            <td>{item.user.name}</td>
            <td>{item.user.email}</td>
            <td className="textCenter">
              {dayjs(item.user.create_at).format('YYYY-MM-DD')}
            </td>
            <td className="textCenter">
              {item.user.login_at !== null
                ? dayjs(item.user.login_at).format('YYYY-MM-DD')
                : '기록 없음'}
            </td>
            <td className="textCenter">
              {item.membership?.plan_name || '없음'}
            </td>
            <td className="textCenter">
              {item.membership?.start_date || '없음'}
            </td>
            <td className="textCenter">
              {item.membership?.start_date || '없음'}
            </td>
            <td className="textCenter">
              <Link to={`/members/${item.user.user_uid}`}>
                <InfoIcon w={5} h={5} />
              </Link>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>결과가 없습니다</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )}
    </tbody>
  );
};

export default MemberTable;
