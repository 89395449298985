import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Flex,
  Select,
} from '@chakra-ui/react';

import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import * as server from 'config/Config';
import { Back, SmallDelete, ModifyBtn } from 'styles/ComponentStyle';
import { CustomInput } from 'Common/CustomInput';
import InfoBox from './InfoBox';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const ModalInfoBox = styled(Flex)`
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #474554;

  > h4 {
    min-width: 100px;
    font-weight: 600;
  }

  > input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: ${props => (props.stay ? 'row' : 'column')};
    gap: 10px;
    padding: 15px 5px;

    > h4,
    p {
      width: ${props => (props.stay ? 'initial' : '100%')};
    }
  }
`;

const ModalDatePickerStyle = styled.input`
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 10px;
  width: 200px;

  &:focus {
    outline: ${props => (props.primary ? 'solid' : 'none')};
  }
`;

const SpanBtn = styled.span`
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`;

const MemInfo = () => {
  const { id } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem('admin'));
  const today = new Date();

  const [Data, setData] = useState({
    membership: '',
    user: '',
  });

  const { membership, user } = Data;

  const [selectedMembership, setSelected] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [passbook, setPassbook] = useState(false);

  const ChangeSelectedAndEndDate = e => {
    setSelected(e.target.value);
    const addMonth = dayjs(membership.start_date).add(
      e.target.value,
      'month'
    ).$d;
    setEndDate(addMonth);
  };

  const ModifyUserData = () => {
    const plan = parseInt(selectedMembership);
    const fomatEndDate = dayjs(endDate).format('YYYY-MM-DD');

    if (membership.bill_service === 'none' && !passbook) {
      toast({
        title: '체크하셨나요?',
        description:
          '멤버십이 없는 유저는 수정시 무통장 입금 체크가 필수입니다.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } else {
      const config = {
        method: 'put',
        url: `${server.SERVER_URL}/user/plan?user_uid=${id}`,
        headers: { Authorization: `Bearer ${admin.token}` },
        data: {
          plan: plan,
          isNoPassbook: passbook,
          endDate: fomatEndDate,
        },
      };

      axios(config)
        .then(response => {
          onClose();
          navigate(0);
          toast({
            title: '성공!',
            description: '수정되었습니다.',
            position: 'top-right',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(error => {
          console.error(error);
          toast({
            title: '실패',
            description: `수정할 내용이 잘 들어갔는지 확인해주세요!`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const UnSubscribe = () => {
    if (window.confirm('해지하시겠습니까?')) {
      axios
        .delete(`${server.SERVER_URL}/user/plan?user_uid=${id}`, {
          headers: { Authorization: `Bearer ${admin.token}` },
        })
        .then(response => {
          navigate(0);
        })
        .catch(error => {
          console.error(error.response);
          toast({
            title: 'error!',
            description: `${error.message}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const DeleteUser = () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      axios
        .delete(`${server.SERVER_URL}/user/?user_uid=${id}`, {
          headers: { Authorization: `Bearer ${admin.token}` },
        })
        .then(response => {
          navigate('/members');
          setTimeout(
            toast({
              title: '성공',
              description: '삭제 되었습니다!',
              position: 'top-right',
              status: 'success',
              duration: 5000,
              isClosable: true,
            }),
            5000
          );
        })
        .catch(error => {
          console.error(error.response);
          toast({
            title: 'error!',
            description: `${error.message}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const fetchData = async e => {
    const formatNext = dayjs(today).add(1, 'day').format('YYYY-MM-DD');

    const config = {
      method: 'get',
      url: `${server.SERVER_URL}/user/profile/${id}`,
      headers: { Authorization: `Bearer ${admin.token}` },
    };

    await axios(config)
      .then(response => {
        const userData = response.data.data;

        setStartDate(new Date(userData.membership?.start_date));
        setEndDate(new Date(userData.membership?.next_date));

        if (userData.membership.bill_service === 'nopassbook') {
          setPassbook(true);
        }

        setData({
          ...Data,
          membership: userData.membership,
          user: userData.user,
        });
        setSelected(userData.membership_legacy.current);
      })
      .catch(error => {
        console.error(error);
        if (error?.response?.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  useEffect(() => {
    const checkTokenAndFetchData = async () => {
      try {
        await verifyTokenAndRefresh(); // 토큰 검증 및 갱신 시도
        fetchData(); // 토큰이 유효하면 데이터 가져오기
      } catch (error) {
        // 토큰 검증 실패 또는 갱신 실패 처리
        toast({
          title: '세션 만료',
          description: '다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 사용자 로그아웃 처리
        // 로그인 페이지로 리다이렉션하거나 다른 조치 취하기
      }
    };

    checkTokenAndFetchData();
  }, []);

  return (
    <>
      <InfoBox
        user={user}
        membership={membership}
        onOpen={onOpen}
        toast={toast}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Box>
              <ModalInfoBox>
                <h4>이름</h4>
                <p>{user.name}</p>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>이메일</h4>
                <p>{user.email}</p>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>가입일자</h4>
                <p>{`${dayjs(user.create_at).format('YYYY-MM-DD')}`}</p>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>최근 로그인</h4>
                <p>{`${dayjs(user.login_at).format('YYYY-MM-DD')}`}</p>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>구독상품</h4>
                <Select
                  border="1px solid #474554"
                  borderRadius={'5px'}
                  p="2px 5px"
                  onChange={ChangeSelectedAndEndDate}
                  value={selectedMembership}
                >
                  <option value="0" disabled>
                    없음
                  </option>
                  <option value="1">1개월</option>
                  <option value="3">3개월</option>
                  <option value="6">6개월</option>
                </Select>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>이용기간</h4>
                <Flex direction={'column'}>
                  <Flex align="center" gridGap={'5px'}>
                    <ModalDatePickerStyle
                      primary
                      value={
                        startDate === 'Invalid Date'
                          ? '없음'
                          : dayjs(startDate).format('YYYY-MM-DD')
                      }
                      readOnly
                    />
                    <SpanBtn onClick={() => setStartDate(today)}>
                      오늘 날짜
                    </SpanBtn>
                  </Flex>
                  ~
                  <Flex justify="space-between" align="flex-start" w="100%">
                    <ModalDatePickerStyle
                      value={dayjs(endDate).format('YYYY/MM/DD')}
                      readOnly
                    />

                    <DatePicker
                      className="IconDatePicker"
                      locale={ko}
                      onChange={date => setEndDate(date)}
                      customInput={<CustomInput />}
                    />
                  </Flex>
                </Flex>
              </ModalInfoBox>
              <ModalInfoBox stay="true">
                <h4>무통장 입금</h4>
                <input
                  type="checkbox"
                  name="nopassbook"
                  value={passbook}
                  onChange={e => setPassbook(e.target.checked)}
                  disabled={membership.bill_service !== 'none' ? true : false}
                />
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>드로잉젤 토큰</h4>
                <p>
                  {user.draw_count > 0
                    ? `${user.draw_count} token`
                    : '토큰이 없습니다'}
                </p>
              </ModalInfoBox>
              <ModalInfoBox>
                <h4>채팅젤 토큰</h4>
                <p>
                  {user.chat_token > 0
                    ? `${user.chat_token} token`
                    : '토큰이 없습니다'}
                </p>
              </ModalInfoBox>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <HStack>
              <Back onClick={UnSubscribe}>구독해지</Back>
              <ModifyBtn onClick={ModifyUserData}>구독수정</ModifyBtn>
              <SmallDelete onClick={DeleteUser}>회원삭제</SmallDelete>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MemInfo;
